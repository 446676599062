var goToTop = {
    step: 0,
    intervalID: null,
    setStep: function (step) {
        this.step = step;
        return this;
    },
    setListener: function (target, duration) {
        var self = this;
        target.addEventListener('click', function () {
            self.intervalID = setInterval(self.scrollTo.bind(self), duration / window.pageYOffset * self.step);
        });
    },
    scrollTo: function () {
        window.pageYOffset === 0 ? clearInterval(this.intervalID) : scrollBy(0, -this.step);
    }
};
var isScrollEnd = function () {
    return (window.innerHeight + window.pageYOffset) >= document.body.clientHeight;
};
var goToLocalLink = {
    step: 0,
    intervalID: null,
    currentTarget: null,
    targetPos: null,
    setStep: function (step) {
        this.step = step;
        return this;
    },
    setListener: function (target, duration) {
        var self = this;
        target.forEach(function (elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                self.currentTarget = e.target;
                if (!self.currentTarget.hasAttribute('href'))
                    return false;
                var anchor = document.querySelector("a[name='" + self.currentTarget.getAttribute('href').substring(1) + "']");
                if (!anchor)
                    return false;
                var rect = anchor.getBoundingClientRect();
                self.targetPos = rect.top + window.pageYOffset;
                self.intervalID = setInterval(self.scrollTo.bind(self), duration / (window.pageYOffset) * self.step);
            });
        });
    },
    scrollTo: function () {
        window.pageYOffset >= this.targetPos || isScrollEnd() ? clearInterval(this.intervalID) : scrollBy(0, +this.step);
    }
};
window.addEventListener('load', function () {
    var STEP = 40;
    goToTop.setStep(STEP).setListener(document.querySelector('.go-to-top'), 100);
    goToLocalLink.setStep(STEP).setListener(document.querySelectorAll('nav a.local'), 100);
});
window.addEventListener('scroll', function () {
    var scrollTop = document.scrollingElement.scrollTop;
    if (scrollTop >= 100) {
        if (!document.querySelector('.go-to-top').classList.contains('active')) {
            document.querySelector('.go-to-top').classList.add('active');
        }
    }
    else {
        if (document.querySelector('.go-to-top').classList.contains('active')) {
            document.querySelector('.go-to-top').classList.remove('active');
        }
    }
    console.log(window.parent.screen.height);
});
